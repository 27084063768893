import { Link } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { capitalize } from 'lodash';
import React, { useMemo } from 'react';

import InfluencerAvatar from '../components/InfluencerAvatar';
import VideoCell from '../components/VideoCell';
import Table, { StatBox, TableText } from '../Table';
import { Campaign } from '../types';
import { SummaryModal } from './SummaryModal';
import { TopMoment } from './types';
import { findInfluencerByMoment, getVideoByMoment } from './utils';

const ROW_HEIGHT = 130;

const Sentiment = ({
  sentiment,
}: {
  sentiment: TopMoment['creator_sentiment'] | TopMoment['audience_sentiment'];
}) => {
  if (sentiment === '') {
    return (
      <TableText
        fontSize="0.8rem"
        style={{ color: 'black', fontWeight: 'bold' }}
      >
        N/A
      </TableText>
    );
  }

  if (sentiment === 'positive') {
    return (
      <TableText
        fontSize="0.8rem"
        style={{ color: 'green', fontWeight: 'bold' }}
      >
        Positive
      </TableText>
    );
  } else if (sentiment === 'negative') {
    return (
      <TableText fontSize="0.8rem" style={{ color: 'red', fontWeight: 'bold' }}>
        Negative
      </TableText>
    );
  } else {
    return (
      <TableText
        fontSize="0.8rem"
        style={{ color: 'gray', fontWeight: 'bold' }}
      >
        Neutral
      </TableText>
    );
  }
};

const normalizeSentiment = (
  sentiment: string | undefined
): TopMoment['creator_sentiment'] => {
  if (!sentiment) {
    return '';
  }

  if (sentiment.toLowerCase().includes('positive')) {
    return 'positive';
  } else if (sentiment.toLowerCase().includes('negative')) {
    return 'negative';
  } else {
    return 'neutral';
  }
};

const getCols = (data: TopMoment[]) => {
  const hasCreatorSentimentColumn = () => {
    return data.some((row) => !!row.creator_sentiment);
  };

  return [
    {
      label: 'Creator',
      key: 'creator',
      render: (row: Row) => {
        return (
          <InfluencerAvatar
            influencer={row.streamer_profile}
            platform={''}
            isDiscovery={false}
          />
        );
      },
      stretch: true,
      flex: 0.75,
    },
    {
      label: 'Video',
      key: 'video',
      render: (row: Row) => {
        if (row.video === null) {
          return <TableText fontSize="0.8rem">{row.video_title}</TableText>;
        }

        return <VideoCell video={row.video} videoTime={''} showType={false} />;
      },
      stretch: true,
      flex: 1.5,
    },
    {
      label: 'Topic',
      key: 'topic',
      render: (row: Row) => {
        return <TableText fontSize="0.8rem">{row.topic}</TableText>;
      },
      stretch: true,
      flex: 0.5,
    },
    {
      label: 'Category',
      key: 'category',
      render: (row: Row) => {
        return <TableText fontSize="0.8rem">{row.category}</TableText>;
      },
      stretch: true,
      flex: 0.5,
    },
    hasCreatorSentimentColumn && {
      label: 'Creator Sentiment',
      key: 'creator_sentiment',
      render: (row: Row) => {
        return <Sentiment sentiment={row.creator_sentiment} />;
      },
      stretch: true,
      flex: 0.75,
    },
    {
      label: 'Audience Sentiment',
      key: 'audience_sentiment',
      render: (row: Row) => {
        return <Sentiment sentiment={row.audience_sentiment} />;
      },
      stretch: true,
      flex: 0.75,
    },
    {
      label: 'Comments',
      key: 'comments',
      render: (row: Row) => {
        return <StatBox stat={row.comments} />;
      },
      stretch: true,
      flex: 0.5,
    },
    {
      label: 'CCV',
      key: 'ccv',
      render: (row: Row) => {
        return <StatBox stat={row.ccv} />;
      },
      stretch: true,
      flex: 0.5,
    },
    {
      label: 'Moment',
      key: 'moment',
      render: (row: Row) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <SummaryModal
              creatorSummary={row.creator_summary}
              audienceSummary={row.audience_summary}
              link={row.link}
              startTime={row.startTime}
              transcript={row.transcript}
              comments={row.commentsItems}
            />
            <Link
              style={{ marginTop: '15px' }}
              href={row.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <OpenInNewIcon fontSize="inherit" style={{ marginRight: 4 }} />
              <span>VIEW MOMENT</span>
            </Link>
          </div>
        );
      },
      stretch: true,
      flex: 0.75,
    },
  ];
};

const getRows = ({
  data,
  campaign,
  originalData,
}: {
  data: TopMoment[];
  campaign: Campaign;
  originalData: TopMoment[];
}) => {
  const topMoments = data.filter(
    (item) => item.moment_starts_here.toLowerCase() === 'true'
  );

  return topMoments.map((topMoment) => {
    // Extract the video url from the moment link which is a timestamped link
    const video = getVideoByMoment(topMoment, campaign.videos);
    const streamerName = topMoment.streamer;

    const streamerProfile = findInfluencerByMoment(
      topMoment,
      campaign.campaign_influencers
    );

    const commentsItems = originalData.filter(
      (item) => item.timeframe === topMoment.timeframe
    );

    return {
      streamer_name: streamerName,
      streamer_profile: streamerProfile || { name: streamerName, avatar: null },
      video: video || null,
      link: topMoment['moment in video'],
      startTime: topMoment.transcript_started_at,
      endTime: topMoment.transcript_ended_at,
      video_title: topMoment['video title'],
      topic: capitalize(topMoment.topic),
      creator_sentiment: normalizeSentiment(topMoment.creator_sentiment),
      audience_sentiment: normalizeSentiment(topMoment.audience_sentiment),
      ccv: topMoment.moment_ccv || null,
      views: video?.total_views || video?.computed_total_views || null,
      creator_summary: topMoment.creator_summary,
      audience_summary: topMoment.audience_summary,
      comments: topMoment.moment_length,
      commentsItems,
      category: capitalize(topMoment.category) || null,
      transcript: topMoment.transcript_text,
      key: topMoment.timeframe,
    };
  });
};

type Row = ReturnType<typeof getRows>[0];

export const TopMomentsTable = ({
  data,
  campaign,
  originalData,
}: {
  data: TopMoment[];
  campaign: Campaign;
  originalData: TopMoment[];
}) => {
  const rows = useMemo(
    () =>
      getRows({
        data,
        campaign,
        originalData,
      }),
    [data, campaign, originalData]
  );

  const cols = useMemo(() => getCols(data), [data]);

  return (
    <>
      <Table
        cols={cols}
        rows={rows}
        rowHeight={ROW_HEIGHT}
        minWidth={1300}
        defaultSortCol="comments"
        idTable={'top_moments_table'}
        sortKey={undefined}
        noBorders={undefined}
        defaultIncreasing={undefined}
        onRowClick={undefined}
        onCreatorColClick={undefined}
      />
    </>
  );
};
