import 'bootstrap';
import './index.css';
import './assets/styles/index.css';
import './assets/styles/index.scss';
import './assets/scripts/index.js';
import './i18n/i18n';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './common/containers/Main';
import * as serviceWorker from './serviceWorker';

console.warn('process.env.NODE_ENV', process.env.NODE_ENV);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://ad25d0e93b534cd29c97f0ed79b77da4@sentry.dev.aggero.io/5',
    integrations: [new Integrations.BrowserTracing()],
    release: process.env.VERCEL_GIT_COMMIT_SHA,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
